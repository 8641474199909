// 首页接口api

import service from "../config.js";
import link from "../link.js";

let ApiUrl = link.IOtApiChen.APIService;

//获取默认自动门信息
export const getDefaultDoor = async (rid) => {
  let responseData = await service({
    url: ApiUrl + "/api/Home/GetDefaultDoor",
    method: "get",
    params: {}
  })
  return responseData;
}

//获取终端在线统计
export const getTerminalOnStatistics = async (rid) => {
  let responseData = await service({
    url: ApiUrl + "/api/Home/GetTerminalOnStatistics",
    method: "get",
    params: {}
  })
  return responseData;
}

//天气
export const getWeather = async () => {
  let responseData = await service({
    url: ApiUrl + "/api/ThirdParty/GetWeather",
    method: "post",
  });
  return responseData;
};