<template>
  <div class="d-switch">
    <input type="radio" :checked="checked" />
    <div
      :style="{
        height: size.includes('px') ? size : size + 'px',
        background: checked ? activeColor : inactiveColor,
      }"
      class="switch-area"
      :class="{ active: checked }"
    >
      <div
        class="on"
        :class="{ active: !onText }"
        :style="{ opacity: checked ? 1 : 0 }"
      >
        {{ onText ? onText : "on" }}
      </div>
      <div
        class="off"
        :class="{ active: !offText }"
        :style="{ opacity: !checked ? 1 : 0 }"
      >
        {{ offText ? offText : "off" }}
      </div>
      <span
        class="slide-btn"
        :style="{
          width: size.includes('px') ? size : size + 'px',
          right: !checked
            ? 'calc(100% - ' + (parseInt(size) + 1) + 'px' + ')'
            : '1px',
          color: checked ? activeColor : inactiveColor,
        }"
      >
        <slot name="icon"></slot>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    activeColor: {
      type: String,
      default: "#ffff00",
    },
    inactiveColor: {
      type: String,
      default: "#e8e8e8",
    },
    size: {
      type: String,
    },
    onText: {
      type: String,
    },
    offText: {
      type: String,
    },
  },
  data() {
    return {};
  },
  methods: {},
  computed: {},
  mounted() {},
  updated() {
    console.log(this.checked);
  },
};
</script>
<style lang="scss">
.d-switch {
  > input {
    display: none;
  }
  .switch-area {
    width: auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    position: relative;
    border-radius: 9999px;
    padding: 0 2px;
    > div {
      font-size: 12px;
      color: #fff;
    }
    > div.on {
      padding-left: 5px;
    }
    > div.off {
      padding-right: 5px;
    }
    > div.on.active {
      padding-left: 10px;
    }
    > div.off.active {
      padding-right: 10px;
    }
    .slide-btn {
      position: absolute;
      top: 0;
      height: 100%;
      background-color: #fff;
      border-radius: 50%;
      transition: all 0.3s ease;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>