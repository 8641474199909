<template>
	<div class="home-menu">
		<van-image
			class="home-menu-bg"
			width="100%"
			height="212px"
			:src="require('@/assets/guardian-bg.png')"
		></van-image>
		<div class="home-menu-title">
			<div>华信合智能管控平台</div>
			<!-- <div><van-icon name="setting-o" /></div> -->
		</div>
		<div class="home-wheather">
			<div>
				<div class="temperature">{{ WeatherInfo.tem }}<i>℃</i></div>
				<div class="wheather">
					<div>
						<i class="iconfont icon-shouye_aqi"></i>
						<span>{{ WeatherInfo.air_level }}</span>
					</div>
					<div>天气 {{ WeatherInfo.wea }}</div>
				</div>
			</div>
		</div>
		<div @click="showPopup = true" class="shortcut">
			<i class="iconfont icon-shouye_kuaijie"></i>
		</div>
		<div class="scroll-container">
			<van-grid :column-num="2" :gutter="16">
				<van-grid-item
					@click="
						$router.push({
							path: item.url,
							query: { ...item.query },
						})
					"
					v-for="(item, index) in list"
					:key="index"
				>
					<div class="controls-list-item">
						<div>
							<div>{{ item.title }}</div>
						</div>
						<div>
							<div>
								<van-image
									width="55px"
									height="55px"
									fit="contain"
									:src="item.img"
								/>
							</div>
							<div>
								<span>{{ item.onNumber }}</span>
								<span>/{{ item.countNumber }}</span>
							</div>
						</div>
					</div>
				</van-grid-item>
			</van-grid>
			<info-block title="警报消息">
				<div class="notice-info">
					<van-cell-group>
						<van-cell
							:title="item.title"
							v-for="(item, index) in noticeInfo"
							:key="index"
							is-link
						/>
					</van-cell-group>
				</div>
			</info-block>
		</div>
		<van-popup v-model="showPopup" :close-on-click-overlay="false">
			<div class="popup-content">
				<!-- <div>快捷方式</div> -->
				<van-cell-group>
					<van-cell>
						<div class="shortcut-title">快捷方式</div>
					</van-cell>
					<div class="popup-shortcut">
						<div>
							<div>
								<div>
									<van-image
										width="65px"
										fit="contain"
										:src="
											noticeInfo[0].checked
												? require('@/assets/自动门-正在打开中.gif')
												: require('@/assets/自动门-正在关闭中.gif')
										"
									/>
								</div>
								<div>
									<div>大门</div>
									<div>{{ noticeInfo[0].checked ? '打开' : '关闭' }}</div>
								</div>
							</div>
							<div>
								<van-button
									type="primary"
									round
									@click="noticeClick(noticeInfo[0])"
									:color="
										noticeInfo[0].checked
											? defineStyle.themeColor
											: defineStyle.color.gray
									"
								>
									<i class="iconfont icon-control_close01"></i>
								</van-button>
							</div>
						</div>
						<div>
							<div>
								<div>
									<van-image
										width="65px"
										fit="contain"
										:src="
											noticeInfo[0].checked
												? require('@/assets/自动门-正在打开中.gif')
												: require('@/assets/自动门-正在关闭中.gif')
										"
									/>
								</div>
								<div>
									<div>大门常开/常闭</div>
									<div>{{ noticeInfo[0].checked ? '打开' : '关闭' }}</div>
								</div>
							</div>
							<div>
								<van-button
									type="primary"
									round
									@click="noticeClick2(noticeInfo[0])"
									:color="
										noticeInfo[0].checked
											? defineStyle.themeColor
											: defineStyle.color.gray
									"
								>
									<i class="iconfont icon-control_close01"></i>
								</van-button>
							</div>
						</div>
						<div>
							<div>
								<div>
									<van-image
										width="65px"
										fit="contain"
										:src="require('@/assets/电视.png')"
									/>
								</div>
								<div>
									<div>电视机</div>
									<div></div>
								</div>
							</div>
							<div>
								<van-button
									type="primary"
									round
									@click="noticeInfo[1].checked = !noticeInfo[1].checked"
									:color="
										noticeInfo[1].checked
											? defineStyle.themeColor
											: defineStyle.color.gray
									"
								>
									<i class="iconfont icon-control_close01"></i>
								</van-button>
							</div>
						</div>
					</div>
				</van-cell-group>
			</div>
			<div class="popup-close">
				<img @click="showPopup = false" :src="require('@/assets/close.svg')" alt="" />
			</div>
		</van-popup>
	</div>
</template>
<script>
import DefineSwitch from '@/components/DefineSwitch.vue'
import defineMethods from '@/methods'
import {
	Image as VanImage,
	Grid,
	GridItem,
	Cell,
	CellGroup,
	Popup,
	Switch,
	Slider,
	Button,
} from 'vant'
import InfoBlock from '@/components/InfoBlock.vue'
import {
	getTerminalOnStatistics,
	getWeather,
	getDefaultDoor,
} from '@/api/TerminalEquipment/HomeApi.js'
export default {
	components: {
		InfoBlock,
		[VanImage.name]: VanImage,
		[Button.name]: Button,
		[Grid.name]: Grid,
		[GridItem.name]: GridItem,
		[Cell.name]: Cell,
		[CellGroup.name]: CellGroup,
		[Popup.name]: Popup,
		[Switch.name]: Switch,
		[Slider.name]: Slider,
		DefineSwitch,
	},
	data() {
		return {
			value: 24,
			checked: false,
			list: [
				{
					type: 1,
					onNumber: 0,
					countNumber: 0,
					img: require('@/assets/灯泡.svg'),
					title: '智能照明灯',
					url: '/SmartClassroom/Filter',
					query: {
						equipment: 0,
					},
				},
				{
					type: 6,
					onNumber: 3,
					countNumber: null,
					img: require('@/assets/camera.png'),
					title: '摄像头',
					number: '3',
					url: '/SmartClassroom/Camera',
					query: {
						equipment: 5,
					},
				},
				{
					type: 3,
					onNumber: 0,
					countNumber: 0,
					img: require('@/assets/窗帘.svg'),
					title: '窗帘',
					number: '917/11278',
					url: '/SmartClassroom/Filter',
					query: {
						equipment: 2,
					},
				},
				{
					type: 4,
					onNumber: 0,
					countNumber: 0,
					img: require('@/assets/自动门.svg'),
					title: '自动门',
					number: '214/12278',
					url: '/SmartClassroom/Filter',
					query: {
						equipment: 3,
					},
				},
				{
					type: 5,
					onNumber: 0,
					countNumber: 0,
					img: require('@/assets/室内检测仪器.svg'),
					title: '室内检测仪',
					number: '512/12278',
					url: '/SmartClassroom/Filter',
					query: {
						equipment: 4,
					},
				},
				{
					// type: 6,
					// onNumber: 3,
					// countNumber: null,
					// img: require('@/assets/camera.png'),
					// title: '摄像头',
					// number: '3',
					// url: '/SmartClassroom/Camera',
					// query: {
					// 	equipment: 5,
					// },
					type: 2,
					onNumber: 0,
					countNumber: 0,
					img: require('@/assets/空调.svg'),
					title: '空调',
					number: '920/11278',
					url: '/SmartClassroom/Filter',
					query: {
						equipment: 1,
					},
				},
			],
			noticeInfo: [
				{
					type: 1,
					title: '大门',
					checked: false,
					serversCode: '',
				},
				{
					type: 2,
					title: '电视机',
					checked: false,
				},
				{
					type: 3,
					title: '二楼筒灯',
					checked: false,
				},
				{
					type: 4,
					title: '射灯',
					checked: false,
				},
				{
					type: 5,
					title: '人脸',
					checked: false,
				},
				{
					type: 6,
					title: '广播寻呼',
					checked: false,
				},
			],
			shortcutList: [
				{
					icon: '',
				},
			],
			showPopup: false,
			WeatherInfo: {
				air: '22', //空气质量
				air_level: '优', //空气质量等级
				air_pm25: '22', //
				air_tips: '空气很好，可以外出活动，呼吸新鲜空气，拥抱大自然！',
				alarm: { alarm_type: '', alarm_level: '', alarm_content: '' },
				city: '深圳',
				cityEn: 'shenzhen',
				cityid: '101280601', //城市ID
				country: '中国', //
				countryEn: 'China',
				date: '2021-02-25',
				humidity: '83%', //湿度
				pressure: '1005', //气压hPa
				tem: '19', //实时温度
				tem1: '26', //高温
				tem2: '19', //低温
				update_time: '2021-02-25 10:05', //气象台更新时间
				visibility: '30km', //能见度
				wea: '雨', //天气情况
				wea_img: 'yu', //天气对应图标	固定9种类型(您也可以根据wea字段自己处理):xue、lei、shachen、wu、bingbao、yun、yu、yin、qing
				week: '星期四',
				win: '东北风',
				win_meter: '小于12km/h', //风速
				win_speed: '1级', //风力等级
			},
		}
	},
	methods: {
		async WeatherDetail() {
			let res = await getWeather()
			this.WeatherInfo = JSON.parse(res.Data)
			/**
       cityid	String	城市ID	
date	String	当前日期	
week	String	当前星期	
update_time	String	气象台更新时间	
city	String	城市名称	
cityEn	String	城市英文名称	
country	String	国家名称	
countryEn	String	国家英文名称	
wea	String	天气情况	
wea_img	String	天气对应图标	固定9种类型(您也可以根据wea字段自己处理):
xue、lei、shachen、wu、bingbao、yun、yu、yin、qing
tem	String	实时温度	
tem1	String	高温	
tem2	String	低温	
win	String	风向	
win_speed	String	风力等级	
win_meter	String	风速	
humidity	String	湿度	
visibility	String	能见度	
pressure	String	气压hPa	
air	String	空气质量	
air_level	String	空气质量等级	
air_tips	String	空气质量描述
       */
		},
		async TerminalOnStatistics() {
			let res = await getTerminalOnStatistics()
			this.list.forEach(q => {
				let info = this.getFirst(res.Data, q.type)
				if (info != null) {
					q.onNumber = info.OnNum
					q.countNumber = info.CountNum
				}
			})
		},
		async defaultDoor() {
			let res = await getDefaultDoor()
			if (res.ResultCode != 200) return false
			if (!defineMethods.isNullOrEmpty(res.Data.SttributeJson)) {
				let fir = JSON.parse(res.Data.SttributeJson)
				this.noticeInfo[0].checked = fir.SWITCH == 'OPEN' ? true : false
				this.noticeInfo[0].serversCode = res.Data.TerminalCode
			}
		},
		//获取单个实体
		getFirst(list, terminaType) {
			let info = null
			list.forEach(a => {
				if (a.TerminalType == terminaType) {
					info = a
				}
			})
			return info
		},
		//开关设备
		noticeClick(val) {
			switch (val.type) {
				case 1:
					let obj = {
						ServersCode: val.serversCode,
						Msg: !val.checked ? '1' : '0', //2,3常开常闭 0,1关闭/打开
						Command: 5,
					}
					console.log(obj)
					this.global.wsSendMsg('door', JSON.stringify(obj))
					val.checked = !val.checked
					break
				default:
					break
			}
		},
		//开关设备
		noticeClick2(val) {
			switch (val.type) {
				case 1:
					let obj = {
						ServersCode: val.serversCode,
						Msg: !val.checked ? '2' : '3', //2,3常开常闭 0,1关闭/打开
						Command: 5,
					}
					console.log(obj)
					this.global.wsSendMsg('door', JSON.stringify(obj))
					val.checked = !val.checked
					break
				default:
					break
			}
		},
	},
	created() {
		// if (this.$store.state.webSocketInfo == null)
		//   this.$store.commit('initWebSocket');
		this.WeatherDetail()
	},
	mounted() {
		this.TerminalOnStatistics()
		this.defaultDoor()
	},
}
</script>
<style lang="scss" scoped>
@import '../../style/public.scss';
.home-menu {
	width: 100%;
	height: 100%;
	position: relative;
	.popup-content {
		.popup-shortcut {
			> div {
				width: 313px;
				margin: 0 auto;
				margin-top: 20px;
				padding: 0 20px;
				height: 76px;
				background: #ffffff;
				box-shadow: 0px 0px 8px rgba(200, 200, 200, 0.5);
				border-radius: 8px;
				display: flex;
				flex-flow: row nowrap;
				justify-content: space-between;
				align-items: center;
				> div:first-child {
					display: flex;
					flex-flow: row nowrap;
					justify-content: space-between;
					align-items: center;
					> div:first-child {
						margin-right: 18px;
					}
					> div:last-child {
						> div:first-child {
							font-size: 14px;
							font-weight: 400;
							color: $titleColor;
						}
						> div:last-child {
							font-size: 12px;
							font-weight: 400;
							color: #949494;
						}
					}
				}
				> div:last-child {
					display: flex;
					flex-flow: row nowrap;
					justify-content: center;
					align-items: center;
				}
			}
			padding-bottom: 20px;
		}
		.van-button {
			border-radius: 50%;
			i {
				font-size: 20px;
			}
		}
		.van-button--normal {
			padding: 0;
			width: 30px;
			height: 30px;
			text-align: center;
		}
	}

	.iconfont.icon-control_deng {
		line-height: 20px;
		margin-right: 5px;
	}
	.scroll-container {
		position: absolute;
		top: 177px;
		left: 0;
		width: 100%;
		height: calc(100% - 177px);
		border-radius: 25px 25px 0px 0px;
		background: #f4f4f5;
		z-index: 10;
		padding-top: 24px;
		overflow: scroll;
		padding-bottom: 70px;
	}
	.controls-list-item {
		> div:first-child {
			color: $titleColor;
			font-size: 16px;
			font-weight: 400;
			text-align: left;
			width: 134px;
		}
		> div:last-child {
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;
			align-items: flex-end;
			margin-top: 13px;
			> div:last-child {
				margin-bottom: 8px;
				> span:first-child {
					color: $themeColor;
					font-size: 18px;
					font-weight: 500;
				}
				> span:last-child {
					display: inline-block;
					font-size: 12px;
					transform: scale(0.95);
					color: $titleColor;
				}
			}
		}
	}
	.notice-info {
		margin: 0 16px;
		background: #fff;
		border-radius: 10px 10px 0px 0px;
		box-shadow: 0px 0px 8px rgba(200, 200, 200, 0.5);
		overflow: hidden;
	}
	&-bg {
		position: relative;
		z-index: 1;
	}

	&-title {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 2;
		height: 38px;
		margin-top: 24px;
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;
		margin-top: 24px;
		> div:first-child {
			font-size: 20px;
			font-weight: 500;
			color: #ffffff;
			margin-left: 24px;
		}
		> div:last-child {
			font-size: 20px;
			font-weight: 500;
			color: #ffffff;
			margin-right: 24px;
		}
	}
	.home-wheather {
		position: absolute;
		top: 102px;
		left: 24px;
		z-index: 3;
		color: #fff;
		> div {
			display: flex;
			flex-flow: row nowrap;
			justify-content: flex-start;
			align-items: center;
			.temperature {
				font-size: 42px;
				font-weight: 300;
				color: #ffffff;
				margin-right: 5px;
				display: flex;
				flex-flow: row nowrap;
				justify-content: space-between;
				align-items: flex-start;
				i {
					font-size: 15px;
					font-weight: 500;
					color: #ffffff;
				}
			}
			.wheather {
				> div:first-child {
					width: 45px;
					height: 18px;
					background: rgba(255, 255, 255, 0.4);
					border-radius: 9px;
					font-size: 12px;
					font-weight: 400;
					color: #ffffff;
					text-align: center;
					padding-right: 5px;
				}
				> div:last-child {
					margin-top: 5px;
				}
			}
		}
	}

	.portrait {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		font-size: 15px;
		font-weight: 400;
		color: #303133;
	}
	.toggle-identity {
		font-size: 12px;
		font-weight: 400;
		color: #303133;
		height: 32px;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: flex-end;
		i {
			font-size: 16px;
			font-weight: bold;
			display: inline-block;
			width: 20px;
			height: 20px;
			text-align: center;
			line-height: 20px;
			color: #fff;
			font-size: 20px;
		}
	}

	.popup-content {
		width: 343px;
		.van-cell-group {
			border-radius: 12px;
			overflow: hidden;
			.van-cell::after {
				transform: scaleY(0.5) translateX(-8px);
			}
		}
		.shortcut-title {
			text-align: center;
			font-size: 16px;
			font-weight: 400;
			color: #2a2a2a;
			background: #fff;
		}
	}
	.popup-close {
		text-align: center;
		margin-top: 25px;
	}
	.shortcut {
		position: absolute;
		bottom: 237px;
		right: 0;
		z-index: 15;
		width: 36px;
		height: 32px;
		background: #ffffff;
		box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
		border-radius: 20px 0px 0px 20px;
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;
		align-items: center;
		> i {
			color: $themeColor;
			font-size: 12px;
		}
	}
}

.van-cell__right-icon {
	color: $gray;
}
.van-popup {
	background: transparent;
}
</style>
<style lang="scss">
.home-menu {
	.van-info {
		top: -5px;
		right: -7px;
		padding: 0;
		width: 25px;
		height: 25px;
		font-size: 14px;
		text-align: center;
		line-height: 25px;
		background: #fc4d4f;
		transform: scale(0.8);
		font-weight: 300;
		border-radius: 50%;
	}
	.van-grid-item {
		.van-grid-item__content {
			padding: 10px;
			border-radius: 15px;
		}
	}
}
</style>
