var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home-menu"},[_c('van-image',{staticClass:"home-menu-bg",attrs:{"width":"100%","height":"212px","src":require('@/assets/guardian-bg.png')}}),_vm._m(0),_c('div',{staticClass:"home-wheather"},[_c('div',[_c('div',{staticClass:"temperature"},[_vm._v(_vm._s(_vm.WeatherInfo.tem)),_c('i',[_vm._v("℃")])]),_c('div',{staticClass:"wheather"},[_c('div',[_c('i',{staticClass:"iconfont icon-shouye_aqi"}),_c('span',[_vm._v(_vm._s(_vm.WeatherInfo.air_level))])]),_c('div',[_vm._v("天气 "+_vm._s(_vm.WeatherInfo.wea))])])])]),_c('div',{staticClass:"shortcut",on:{"click":function($event){_vm.showPopup = true}}},[_c('i',{staticClass:"iconfont icon-shouye_kuaijie"})]),_c('div',{staticClass:"scroll-container"},[_c('van-grid',{attrs:{"column-num":2,"gutter":16}},_vm._l((_vm.list),function(item,index){return _c('van-grid-item',{key:index,on:{"click":function($event){return _vm.$router.push({
						path: item.url,
						query: Object.assign({}, item.query),
					})}}},[_c('div',{staticClass:"controls-list-item"},[_c('div',[_c('div',[_vm._v(_vm._s(item.title))])]),_c('div',[_c('div',[_c('van-image',{attrs:{"width":"55px","height":"55px","fit":"contain","src":item.img}})],1),_c('div',[_c('span',[_vm._v(_vm._s(item.onNumber))]),_c('span',[_vm._v("/"+_vm._s(item.countNumber))])])])])])}),1),_c('info-block',{attrs:{"title":"警报消息"}},[_c('div',{staticClass:"notice-info"},[_c('van-cell-group',_vm._l((_vm.noticeInfo),function(item,index){return _c('van-cell',{key:index,attrs:{"title":item.title,"is-link":""}})}),1)],1)])],1),_c('van-popup',{attrs:{"close-on-click-overlay":false},model:{value:(_vm.showPopup),callback:function ($$v) {_vm.showPopup=$$v},expression:"showPopup"}},[_c('div',{staticClass:"popup-content"},[_c('van-cell-group',[_c('van-cell',[_c('div',{staticClass:"shortcut-title"},[_vm._v("快捷方式")])]),_c('div',{staticClass:"popup-shortcut"},[_c('div',[_c('div',[_c('div',[_c('van-image',{attrs:{"width":"65px","fit":"contain","src":_vm.noticeInfo[0].checked
											? require('@/assets/自动门-正在打开中.gif')
											: require('@/assets/自动门-正在关闭中.gif')}})],1),_c('div',[_c('div',[_vm._v("大门")]),_c('div',[_vm._v(_vm._s(_vm.noticeInfo[0].checked ? '打开' : '关闭'))])])]),_c('div',[_c('van-button',{attrs:{"type":"primary","round":"","color":_vm.noticeInfo[0].checked
										? _vm.defineStyle.themeColor
										: _vm.defineStyle.color.gray},on:{"click":function($event){return _vm.noticeClick(_vm.noticeInfo[0])}}},[_c('i',{staticClass:"iconfont icon-control_close01"})])],1)]),_c('div',[_c('div',[_c('div',[_c('van-image',{attrs:{"width":"65px","fit":"contain","src":_vm.noticeInfo[0].checked
											? require('@/assets/自动门-正在打开中.gif')
											: require('@/assets/自动门-正在关闭中.gif')}})],1),_c('div',[_c('div',[_vm._v("大门常开/常闭")]),_c('div',[_vm._v(_vm._s(_vm.noticeInfo[0].checked ? '打开' : '关闭'))])])]),_c('div',[_c('van-button',{attrs:{"type":"primary","round":"","color":_vm.noticeInfo[0].checked
										? _vm.defineStyle.themeColor
										: _vm.defineStyle.color.gray},on:{"click":function($event){return _vm.noticeClick2(_vm.noticeInfo[0])}}},[_c('i',{staticClass:"iconfont icon-control_close01"})])],1)]),_c('div',[_c('div',[_c('div',[_c('van-image',{attrs:{"width":"65px","fit":"contain","src":require('@/assets/电视.png')}})],1),_c('div',[_c('div',[_vm._v("电视机")]),_c('div')])]),_c('div',[_c('van-button',{attrs:{"type":"primary","round":"","color":_vm.noticeInfo[1].checked
										? _vm.defineStyle.themeColor
										: _vm.defineStyle.color.gray},on:{"click":function($event){_vm.noticeInfo[1].checked = !_vm.noticeInfo[1].checked}}},[_c('i',{staticClass:"iconfont icon-control_close01"})])],1)])])],1)],1),_c('div',{staticClass:"popup-close"},[_c('img',{attrs:{"src":require('@/assets/close.svg'),"alt":""},on:{"click":function($event){_vm.showPopup = false}}})])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home-menu-title"},[_c('div',[_vm._v("华信合智能管控平台")])])}]

export { render, staticRenderFns }