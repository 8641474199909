var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-switch"},[_c('input',{attrs:{"type":"radio"},domProps:{"checked":_vm.checked}}),_c('div',{staticClass:"switch-area",class:{ active: _vm.checked },style:({
      height: _vm.size.includes('px') ? _vm.size : _vm.size + 'px',
      background: _vm.checked ? _vm.activeColor : _vm.inactiveColor,
    })},[_c('div',{staticClass:"on",class:{ active: !_vm.onText },style:({ opacity: _vm.checked ? 1 : 0 })},[_vm._v(" "+_vm._s(_vm.onText ? _vm.onText : "on")+" ")]),_c('div',{staticClass:"off",class:{ active: !_vm.offText },style:({ opacity: !_vm.checked ? 1 : 0 })},[_vm._v(" "+_vm._s(_vm.offText ? _vm.offText : "off")+" ")]),_c('span',{staticClass:"slide-btn",style:({
        width: _vm.size.includes('px') ? _vm.size : _vm.size + 'px',
        right: !_vm.checked
          ? 'calc(100% - ' + (parseInt(_vm.size) + 1) + 'px' + ')'
          : '1px',
        color: _vm.checked ? _vm.activeColor : _vm.inactiveColor,
      })},[_vm._t("icon")],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }